<template>
  <section>
    <b-row>
      <b-col md="4">
        <impressoras />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Impressoras from './Impressoras.vue'

export default {
  name: 'Index',
  components: {
    Impressoras,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>
