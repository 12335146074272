<template>
  <section>
    <b-card title="Configuração Impressora">
      <div v-for="set in setores" :key="set.id" class="mt-1" style="border: solid 1px #d9d9d9; border-radius: 7px">
        <div style="color: black; font-weight: 600; padding: 10px; display: flex;justify-content: space-between;">
          <div style="display: flex; ">
            <div>
              {{ set.nome }}
            </div>
            <b-button class="ml-1 botaoEditar" @click="editarImpressora(set)">
              Editar
            </b-button>
          </div>
        </div>
        <div style="height: 1px; margin-bottom: 5px; background-color: #d0d0d0" />
        <div style="padding: 10px;">
          <div>
            Impressora padrão:
            <b-badge :variant="verificaImpPadrao(set.padrao).cor">
              {{ verificaImpPadrao(set.padrao).nome }}
            </b-badge>
          </div>
          <div> Papel padrão:{{ set.papel }} </div>
        </div>
      </div>
      <template #footer style="align-self: center; !important">
        <b-button href="https://arquivos.devnx.com.br/devnx/uploads/pdv/impressora.exe" variant="outline-primary"
          style="width: 100%; !important">
          Baixar programa impressora
        </b-button>
      </template>
    </b-card>
    <b-modal id="add-impressora" centered hide-footer title="Adicionar impressora" :no-close-on-backdrop="true">
      <b-form-group label="Selecione a impressora desejada">
        <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
          :options="impressoras" />
      </b-form-group>
      <b-button variant="outline-success" style="margin-right: 10px" @click="salvarImpressora">
        Salvar
      </b-button>
      <b-button variant="outline-danger" @click="$bvModal.hide('add-impressora')">
        Cancelar
      </b-button>
    </b-modal>

    <b-modal id="add-setor" ref="addSetor" centered hide-footer title="Configuração do setor">
      <div class="mt-1">
        <b-form-group label="Impressora:">
          <v-select v-model="selected" :options="impressoras" />
        </b-form-group>
      </div>
      <div class="mt-2 mb-2">
        <b-button variant="outline-success">
          Salvar Impressora
        </b-button>
        <b-button class="ml-1" variant="outline-danger" @click="$bvModal.hide('add-setor')">
          Cancelar
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

export default {
  name: 'Impressoras',
  data() {
    return {
      impressoras: [],
      setores: [],
      selecionado: null,
      setor: null,
      selected: {},
    }
  },
  async created() {
    this.carregatodasAsConfigs()
    this.buscaImp()
  },
  methods: {
    async buscaImp() {
      await this.carregaImressoras()
      this.$http.get('printer/userget').then(resp => {
        this.setores = resp.data
        localStorage.setItem('impressao', JSON.stringify(resp.data))
      })
    },
    async carregatodasAsConfigs() {
      // eslint-disable-next-line no-undef
      if (!qz.websocket.isActive()) {
        // eslint-disable-next-line no-undef,no-unused-vars
        await qz.security.setCertificatePromise((resolve, reject) => {
          resolve('-----BEGIN CERTIFICATE-----\n'
            + 'MIID6zCCAtOgAwIBAgIUX7m6uWuxPNE4JEsAuMcSiS5+l7MwDQYJKoZIhvcNAQEL\n'
            + 'BQAwgYMxCzAJBgNVBAYTAkJSMQ8wDQYDVQQIDAZQQVJBTkExETAPBgNVBAcMCENV\n'
            + 'UklUSUJBMQ4wDAYDVQQKDAVERVZOWDENMAsGA1UECwwEQkFDSzEOMAwGA1UEAwwF\n'
            + 'RkFCSU8xITAfBgkqhkiG9w0BCQEWEkZBQklPVEdLQEdNQUlMLkNPTTAgFw0yMjAx\n'
            + 'MTEyMzMyNDBaGA8yMDUzMDcwNjIzMzI0MFowgYMxCzAJBgNVBAYTAkJSMQ8wDQYD\n'
            + 'VQQIDAZQQVJBTkExETAPBgNVBAcMCENVUklUSUJBMQ4wDAYDVQQKDAVERVZOWDEN\n'
            + 'MAsGA1UECwwEQkFDSzEOMAwGA1UEAwwFRkFCSU8xITAfBgkqhkiG9w0BCQEWEkZB\n'
            + 'QklPVEdLQEdNQUlMLkNPTTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEB\n'
            + 'AMqiy0O8iu5T0xNn4eJ/eX6GYEkRcPUk2V97MNEEpxjT/2GjtohseidT5OmUPFke\n'
            + 'HAUM8gc5vjrnz3t9qRqyfxCInlRFLVKcP4OAfn9QVtPIBkYYqU0DtG0L/n3z1Lib\n'
            + 'zMT8wIxJNzue+6Ga+/kSGfsYL45RjxTkiJwiav5VMc+IcWLXti5ZEkaHgSkxcrQN\n'
            + 'rqNurCyxWVDsONWHNFWi7xfqN1KvhkXe5L3cQPhju1dz1kiv8FMjavO6zgsM4wIf\n'
            + 'y4au28zVsAkGwVAckcWckM2tG94PJGbtNQDBdYEL/C2DJYNLqQ+5abO5wWR5PBW2\n'
            + 'hinl4X97lrIRD6+qNu7wcZECAwEAAaNTMFEwHQYDVR0OBBYEFG2RMM0BAjkNHjbE\n'
            + 'CnJfZXLoyuRUMB8GA1UdIwQYMBaAFG2RMM0BAjkNHjbECnJfZXLoyuRUMA8GA1Ud\n'
            + 'EwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBACoJvrk+7knxe5StldXv0PvA\n'
            + 'w8xq9nN0vjLegzdQ2Wh0KaNBr07bfx343dCTr9v+w9rtu3xtsaUUc82hYN+L9RVJ\n'
            + 'zuRwexaXoN50L7ff5wDGDWuaeL2ScEWNJcHndDMcXU7HYfTCUS2qh1CM1m+zve1j\n'
            + 'vyA3f7KamO4N1XNMlIJf7eDRufvgVSAUlf6FVwIQwTcuFONK1/q++7hVr5ACogoY\n'
            + 'pCLzR2JRpGVL7w53t+Sh2RtLNzXDhBIuI468liNr1mkSVJwXfxchx+gWJvsvHXaD\n'
            + 'xMWSm+AtCBFK2NgMRbcvn9VHgToDCrX3YMuBeuaAqVOhsFHjmf3mFuzB66ik6h0=\n'
            + '-----END CERTIFICATE-----\n')
        })
        // eslint-disable-next-line no-undef,no-unused-vars
        await qz.security.setSignatureAlgorithm('SHA512') // Since 2.1
        // eslint-disable-next-line no-undef
        await qz.websocket.connect()
      }
    },
    async salvarImpressora() {
      const obj = {
        iimp: this.setor,
        impressora: this.selected,
      }
      this.$bvModal.hide('add-impressora')
      this.$loading(true)
      await this.$http.post('printer/saveuser', obj)
      this.$loading(false)
      const index = this.setores.findIndex(set => this.setor === set.id)
      this.setores[index].padrao = this.selected
      await this.carregaImressoras()
      localStorage.setItem('impressao', JSON.stringify(this.setores))
    },
    editarImpressora(setor) {
      this.setor = setor.id
      if (this.impressoras.includes(setor.padrao)) {
        this.selected = { title: setor.padrao }
      } else {
        this.selected = null
      }
      this.$bvModal.show('add-impressora')
    },
    verificaImpPadrao(impPadrao) {
      let ip = { nome: impPadrao, cor: 'light-danger' }
      this.impressoras.forEach(impressoras => {
        if (impPadrao === impressoras) ip = { nome: impPadrao, cor: 'light-success' }
        if (impPadrao === null || impPadrao.length < 1) ip = { nome: 'Nenhuma impressora', cor: 'light-danger' }
      })
      return ip
    },
    async carregaImressoras() {
      // eslint-disable-next-line no-undef
      this.impressoras = await qz.printers.find()
    },
  },
}
</script>

<style scoped>
.botaoEditar {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 11px;
  background-color: transparent !important;
  color: #505050 !important
}

.imp-estilo {
  padding: 10px;
  border: 1px #e3e3e3 solid;
  display: inline-flex;
  border-radius: 7px
}
</style>
